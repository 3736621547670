import React, { useContext, useEffect, useState } from 'react';
import { Base } from '../../lib/class/Base';
import { Header } from '../../ui/Header';
import { GlobalContext } from '../../ui/withGlobalContext';
import { Job } from '../../lib/class/Job';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { Tech } from '../../lib/class/Tech';
import { Task } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import Link from '../../ui/Link';



type Detailed = {
    job:Job;
    site:Site;
    tech:Tech;
    time:Moment;
    task:Task;
    status:string;
    jobType:string
    taskType:string;
    passed:boolean;
    meterModel:string;
}

type Result = {
    detailed:Detailed[]
}

export function JobSummary(props:any){
    const global = useContext(GlobalContext);
	const {ds} = global;

    const styles = {
        root:{
            paddingTop:64,
			paddingBottom:64,
            paddingLeft:20,
            paddingRight:20
        }
    }

    useEffect(() => {
        run();
    },[])

    const [result,setResult] = useState<Result|null>(null);

    function run(){
        const tasks:Task[] = ds.db.filter({type:"Task"});
        const completed = tasks.filter(j => j.isSuccessful() && j.typeID !== 9 )
        console.log(completed[0]);
        const detailed:Detailed[] = completed.map(task => {
            const job = task.getJob()
            const tech = task.getTech()
            const site = job.getSite();
            const time = task.getEndTimestamp();
            const status = task.getTaskStatus();
            const jobType = job.getType().toString();

            const taskType = task.getType().toString();

        const meterModel = job.getJobDataItemValueString("Meter Model").toString();


            const passed = task.getTestPass();

            








            return {time, job, task, tech, site, status, jobType,taskType, meterModel, passed};
    
        })
        .filter(j => j.status !== "Cancelled")
        .sort((a,b) => a.time.valueOf() - b.time.valueOf());
        setResult({detailed});
    }








    if(result === null) return <div style={styles.root}>
         <Header title="Job Summary" back={true} />
         loading
    </div>

    const models = ["400","410", "420", "430", "450", "500", "510","520"];

 

    return <div style={styles.root}>
        <Header title="Job Summary" back={true} />
    
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Model</TableCell>
                    <TableCell>Count</TableCell>
                    <TableCell>Pass</TableCell>
                    <TableCell>Fail</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {models.map(model => <PrintRow key={model} model={model} detailed={result.detailed.filter(j => j.meterModel === model )} />)}
                <PrintRow model={'total'} detailed={result.detailed} />
            </TableBody>
        </Table>





        <pre>
            {result.detailed.map((j,i) => <DebugRow key={i} detailed={j} />)}
        </pre>




    </div>

}



interface DebugRowI{
    detailed:Detailed;
}

function DebugRow(props:DebugRowI){
    const {detailed} = props;
    const {time, site, jobType, status, job, taskType, task, passed, tech, meterModel} = detailed;
    return <div>
        <Link to={`/task/${task.id}`}>
        {time.format("DD/MM/YY HH:mm")} {jobType}:{taskType} {site.nmi} {status} {tech.firstName} {meterModel} {passed ? "PASS" : "FAIL"}
        </Link>
    </div>


}









interface PrintRowI{
    model:string;
    detailed: Detailed[];

}

function PrintRow(props:PrintRowI){
    const {model,detailed} = props;

    const passed = detailed.filter(j => j.passed );
    const failed = detailed.filter(j => !j.passed );


    const styles ={
        base:{
        }
    }


    return <TableRow style={styles.base}>
        <TableCell>{model}</TableCell>
        <TableCell>{detailed.length}</TableCell>
        <TableCell>{passed.length}</TableCell>
        <TableCell>{failed.length}</TableCell>
    </TableRow> 


}


