import React, { useContext,useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Divider, Typography, Button, CardContent, Card} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import useRouter from 'use-react-router';
import { downloadTextFile } from '../../lib/download';
import { Job } from '../../lib/class/Job';

import prettyBytes from "pretty-bytes";
import { injectMyEvents } from '../../lib/InjectEvents';
import { Site } from '../../lib/class/Site';
import { TestResult } from '../../lib/class/TestResult';
import { Task } from '../../lib/class/Task';
import moment from 'moment';
import { uniq } from 'lodash';
//import { ListProcessorWorkerManager } from '../../workers';
// import { testWorker } from '../../workers';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));

export function Admin(){

    const router = useRouter();
    const global = useContext(GlobalContext);
	const {ds,project,theme,google} = global;

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64,
            paddingLeft:20,
            paddingRight:20
		}
	}


    async function forceLogout(){
        await ds.logout(true);
        nav("/login");
    }

    async function injectMyEvent(){
        await injectMyEvents(ds);
    }

    async function clearEvents(){
        await ds.clearEvents();
    }    
    async function clearMyEvents(){
        await ds.clearMyEvents();
    }

    async function cheekyBlock(){
        await ds.api.request("cheekyBlock");
    }
 
    function nav(link:string){
        router.history.push(link)
    }

    async function injectAPI(){
        const result = await ds.injectAPI();
        console.log(result);
    }

    async function loadFromAPI(){
       await ds.loadAPI();
    }


    function reprocessLists(){
        ds.lp.reset();
        const task = ds.getTask("process");
        task.forceRun();
    }


    async function createMissingData(){
        const tasks = ds.db.filter({type:"Task"});

        for(const task of tasks)
            await task.createMissingData();

        const jobs = ds.db.filter({type:"Job"});
            for(const job of jobs)
                await job.createMissingData();


    }


    async function geocodeSites(){
        const sites:Site[] = ds.db.filter({type:"Site"}).filter(s => (typeof s.location == "undefined") || s.location == null);
    


        for(let i = 0; i < sites.length; i++){
            const site = sites[i];
            try{
                console.log(`${i}/${sites.length} ${site.nmi} - ${site.address}`);
                await site.geocode(google)
            }catch(E){
                console.log(E);
            }
        }
        

        ds.forceSave();


    }

    // async function ignoreJobs(){
    //     let currentNMIs = ["6001256965", "6001248330", "6001245389", "6001252215", "6001003729", "6001259232", "6001261824", "6001296207"];
    //     const all:Job[] = ds.db.filter({type:"Job"});
    //     let pending = all.filter(j => j.isPending())
        
    //     let count = 0;

    //     for(let job of pending){
    //         let site = job.getSite();
    //         if(currentNMIs.indexOf(site.nmi) == -1){
    //             let d = {ignore:true};
    //             console.log(job,d);
    //             count++;
    //             await ds.updateEntity(job,d);
    //         }

    //     }
    // }
// <Button fullWidth  variant="outlined" color="primary" onClick={ignoreJobs}>Batch Ignore</Button>
               


    async function testWorkerRun(){
        //let result = await dsm.get(0);
        //console.log(result);
            

    }

    async function removeOldTestRecords(){
        const d = new Date(2022,0,1)
        console.log("removeOldTestRecords");
       const tests =  ds.db.filter({type:"TestResult"}).filter( (t:TestResult) => t.getError() !== ""  ).filter( v => v.timestamp < d.valueOf() )
       console.log(tests);
       for(const test of tests)
        await ds.updateEntity(test,{deleted:true})

    }



    const size = JSON.stringify(ds.toJSON()).length;
    const humanSize = prettyBytes(size);


    async function downloadEvents(){
        const events = ds.events.map(e => JSON.stringify(e) ).join("\n");
        const myEvents = ds.myEvents.map(e => JSON.stringify(e) ).join("\n");
        const data = `events\n${events}\nmyEvents\n${myEvents}`;

        downloadTextFile(data,"datastore.json");
    }


    async function reprocessIssues(){
        // const d = new Date(2023,0,1)

        try{
            ds.db
            .filter({type:"Task"})
            // .filter(t =>t.typeID === 51416 ||  t.typeID === 203 ) 
            // .filter(t => t.startTimestamp > d.valueOf()  )
            .map((t:Task) => t.processIssues());
        }catch(E){
            console.log("failed to process task issue")
            console.error(E);
        }

        try{
            ds.db
            .filter({type:"Job"})
            // .filter(t =>  t.typeID === 22588 || t.typeID === 198  ) //t.typeID === 198 ||
            .map(t => t.processIssues());
        }catch(E){
            console.log("process job issue")
        }

        try{
            reprocessLists();
        }catch(E){
            console.log("process list issue")
        }
    }


    async function unselectIgnore(){

        ds.db.filter({type:"Task"}).filter(t => t.typeID === 203).map( (t:Task) => ds.updateEntity(t,{ignoreValidation:false}));

    }



    async function clearNullWorkOrder(){

    }



    async function fixDates(){
           ds.db.filter({type:"Job"}).filter(t => t.typeID === 22588)
            .map( (t:Task) => ds.updateEntity(t,{startDate:moment("25/10/22","DD/MM/YY").valueOf(),dueDate:moment("30/12/22","DD/MM/YY")}));
    }



    async function removeOldPending(){

         const tests =  ds.db.filter({type:"Job"}).filter( (v:Job) => !v.isIgnored() && v.isPending() )
       console.log(tests);
       for(const test of tests)
        await ds.updateEntity(test,{deleted:true})
    }



    async function removeRevokedMeters(){

        const tests =  ds.db.filter({type:"Job"}).filter( (v:Job) => v.isPending() ).filter(t => {
            const m = t.getJobDataItemValueString('Meter Model').toString();
            return (m=== "400"  || m === "540");
        });
        
      console.log(tests);
    //   for(const test of tests)
    //    await ds.updateEntity(test,{deleted:true})
   }


   async function createUnit(){

    const unit = {
        "type": "TestUnit",
        "deleted": false,
        "model": "689B",
        "serialNumber": "8639",
        "nataReference": "8639-01",
        "lastCalibrationDate": new Date('27/05/2024').valueOf(),
        "nextCalibrationDate": new Date('27/05/2025').valueOf(),
     }
     ds.createEntity(unit);


   }




    async function cleanDatastore(){
        const types = ['Job','Task','Site','AutoTestResult','BackupAction','Base','JobData','Session','Site','SiteData','TaskData','TestResult','TestResultRow','VirtualFile']
        const ids = ds.db.myEventResult.filter(i => types.includes(i.type)).map(e => e.id) //.filter({type:'Job'}).map((j:Job) => j.id)
        console.log(ids);

        const cleanedEvents =  ds.events.filter(e => !ids.includes(e.filter.id));
        
        const oldIds = cleanedEvents.filter(t => t.action === 'create').map(c => c.filter.id);


        const idList = uniq(oldIds).sort((a,b) => a-b)

        console.log(idList);

        function remap(oldId:number|null){
            if(oldId === null) return null;
            if(typeof oldId !== 'number') throw new Error(`oldId ${oldId} incorrect type ${typeof oldId}`)
            const id = idList.findIndex(v => v === oldId);
            if(id === -1) throw new Error(`old ID: ${oldId}`)
            return id;
        }


        const remapped = cleanedEvents.map(e => {
            e.userID = remap(e.userID) ?? 0
            if('id' in e.filter) e.filter.id = remap(e.filter.id);
            if('id' in e.data) e.data.id = remap(e.data.id);
            if('techID' in e.data) e.data.techID = remap(e.data.techID);
            if('jobTypeID' in e.data) e.data.jobTypeID = `${remap(parseInt(e.data.jobTypeID))}`;
            if('dataTypeID' in e.data) e.data.dataTypeID = `${remap(parseInt(e.data.dataTypeID))}`;
            if('parentID' in e.data) e.data.parentID = remap(e.data.parentID);
            if('taskTypeID' in e.data) e.data.taskTypeID = `${remap(parseInt(e.data.taskTypeID))}`;
            if('typeID' in e.data) e.data.typeID = remap(e.data.typeID);
            if('fileID' in e.data) e.data.fileID = null



            
            return e;
        })

        const events = remapped.map(e => JSON.stringify(e) ).join("\n");
        downloadTextFile(events,"datastore.json");
    }




   async function updateJHa(){
        const rows:[string,boolean][] = [
            ["JHA Instructions",true],
            ["JHA PPE",true],
            ["JHA Permits",false],
            // ["JHA Permit Valid"],
            ["JHA Qualified",true],
            ["JHA Access",true],
            ["JHA Induction",false],
            ["JHA Manual Handling",false],
            ["JHA Traffic",false],
            ["JHA Noise",false],
            ["JHA Machinery",false],
            ["JHA Asbestos",false],
            ["JHA Isolated",true],
            ["JHA Live Points",true],
            ["JHA Clean",true],
            ["JHA Mechanical Aids",false],
            ["JHA Trolley",false],
            ["JHA Colleague",false],
            ["JHA Other",false],
            ["JHA Vehicle",false],
            ["JHA Pedestrian",false],
            ["JHA Access Permit Issued",false],
            ["JHA Insulated Gloves",true],
            ["JHA Mats/Covers Safety",false],
            ["JHA Observers Required",false]
        ];


        const preLimTasks:Task[] = ds.db.filter({type:"Task", typeID:9});
        const ctTestTasks:Task[] = ds.db.filter({type:"Task", typeID:10});

        for(const t of preLimTasks){
            for(const r of rows){
                const v = t.getDataString(r[0])
                console.log(t.id, r[0])
                if(v === null)
                await t.upsertTaskData(r[0], {'value':r[1]})
            }
        }


        for(const t of ctTestTasks){
            for(const r of rows){
                const v = t.getDataString(r[0])
                console.log(t.id, r[0])
                if(v === null)
                await t.upsertTaskData(r[0], {'value':r[1]})
            }
        }


        console.log(preLimTasks);
        console.log(ctTestTasks);



    }



  const classes = useStyles();



	
	return <div style={styles.root}>
		<Header title="Settings" back={true} />

            <Card>
                <CardContent>
                    <Typography variant="h5">Actions</Typography>
                </CardContent>
                <Divider />
                <CardContent>
                    <div className={classes.root}>

                    <Button fullWidth  variant="outlined" color="primary" onClick={reprocessLists}>Reprocess Lists</Button>
                    <Button fullWidth variant="outlined" color="primary" onClick={reprocessIssues}>Reprocess Issues</Button>

                <br/><br/><br/><br/>
                    <Button fullWidth variant="outlined" color="primary" onClick={() => forceLogout()}>Force Logout</Button>   
                    </div>
                </CardContent>
            </Card>

            <br/>
            <Card>
                <CardContent>
                    <Typography variant="h5">Exports</Typography>
                </CardContent>
                <Divider />
                <CardContent>
                    <div className={classes.root}>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/profit-loss-analysis")}>Profit Loss Analysis</Button>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/job-summary")}>Job Summary</Button>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/batch-report-exporter")}>Batch report exporter</Button>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/csv-exporter")}>CSV exporter</Button>

                    </div>
                </CardContent>
            </Card>
                    
            <br/>
            <Card>
                <CardContent>
                    <Typography variant="h5">Imports</Typography>
                </CardContent>
                <Divider />
                <CardContent>
                    <div className={classes.root}>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/csv_import")}>CSV Import</Button>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/test_result_import")}>Test Result Import</Button>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/test_result_sync_links")}>Test Result Sync Links</Button>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/form-template-editor")}>Form Template Editor</Button>
          
                        <Button fullWidth variant="outlined" color="primary" onClick={unselectIgnore}>Unselect ignore</Button>
                        <Button fullWidth variant="outlined" color="primary" onClick={fixDates}>fixDates</Button>
                        

<Button fullWidth variant="outlined" color="primary" onClick={removeOldPending}>remove old jobs</Button>

                        
                    </div>
                    </CardContent>
                </Card>

            <br/>
            <Card>
                <CardContent>
                    <Typography variant="h5">Lists</Typography>
                    </CardContent>
                    <Divider />
                    <CardContent>
                        <div className={classes.root}>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/Base")}>Entities</Button>
                              <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/admin/create_entity")}>Create Entity</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/TestResult")}>Test Results</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/AutoTestResult")}>Auto Test Results</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/TestResultRow")}>Test Result Rows</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/TestResult590BV2")}>590BV2 Test Result Rows</Button>
                        </div>
                </CardContent>
            </Card>
            <br/>
            <br/>
            <Card>
                <CardContent>
                    <Typography variant="h5">Admin Lists</Typography>
                    </CardContent>
                    <Divider />
                    <CardContent>
                        <div className={classes.root}>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/DataEvent")}>Events</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/Requests")}>Requests</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/Errors")}>Request Errors</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/Backups")}>Backup List</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/KeyConflicts")}>Key Conflicts</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/list/UncaughtExceptions ")}>Unhandled Exceptions</Button>

                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/createDocument")}>Create Document</Button>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/batch-tester")}>Batch Component Tester</Button>
                        </div>
                </CardContent>
            </Card>
            <br/>
    

     <Card>
        <CardContent>
            <Typography variant="h5">Datastore</Typography>
        </CardContent>
        <Divider />
        <CardContent>
            <div>AutoSync: {ds.autoSync.toString()}</div>
            <div>Debug: {ds.debug.toString()}</div>
            <div>Events: {ds.events.length}</div>
            <div>My Events: {ds.myEvents.length}</div>
            <div>Entities Result: {ds.db.eventResult.length}</div>
            <div>My Entities Result: {ds.db.myEventResult.length}</div>
            <div>Cache size:{humanSize}</div>
            <br/>
            <pre>{JSON.stringify(ds.toJSONDebug(),undefined,3)}</pre>

            <br/>
            <div className={classes.root}>
                <Button fullWidth  variant="outlined" color="primary" onClick={() => ds.toggleAutoSync()}>Toggle AutoSync</Button>
                <Button fullWidth  variant="outlined" color="primary" onClick={() => ds.toggleDebug()}>Toggle Debug</Button>
                <Button fullWidth  variant="outlined" color="primary" onClick={() => ds.forceSave()}>Save</Button>
                <Button fullWidth  variant="outlined" color="primary" onClick={injectMyEvent}>Inject events</Button>
                <Button fullWidth  variant="outlined" color="secondary" onClick={clearEvents}>Clear events</Button>
                <Button fullWidth  variant="outlined" color="secondary" onClick={clearMyEvents}>Clear my events</Button>
                <Button fullWidth  variant="outlined" color="primary" onClick={clearMyEvents}>Commit my events</Button>
                <Button fullWidth  variant="outlined" color="primary" onClick={createMissingData}>Create Missing Data</Button>
                <Button fullWidth  variant="outlined" color="primary" onClick={clearNullWorkOrder}>Clear Null Work order</Button>
                <Button fullWidth  variant="outlined" color="primary" onClick={removeRevokedMeters}>removeRevokedMeters</Button>


                
            </div>   


            
        </CardContent>
     
     </Card>

     <br/>


     <Card>
        <CardContent>
            <Typography variant="h5">API</Typography>
        </CardContent>
        <Divider />
        <CardContent>
            <div className={classes.root}>
   
                <Button fullWidth variant="outlined" color="primary" onClick={injectAPI}>Inject API</Button>   
                <Button fullWidth variant="outlined" color="primary" onClick={loadFromAPI}>Load from API</Button>   
                <Button fullWidth variant="outlined" color="primary" onClick={downloadEvents}>Download Events</Button>   
                <Button fullWidth variant="outlined" color="primary" onClick={cheekyBlock}>Cheeky Block</Button>

                <Button color="primary" variant="outlined" fullWidth onClick={() => ds.processUploads()} >Process Uploads</Button>
                <Button color="primary" variant="outlined" fullWidth onClick={() => ds.clearUploadCache()} >clearUploadCache</Button>   
        </div>    

</CardContent>

</Card>


<br/>

<Card>
        <CardContent>
            <Typography variant="h5">Datastore Tasks</Typography>
        </CardContent>
        <Divider />
        <CardContent>
            <div className={classes.root}> 
            <Button fullWidth variant="outlined" color="primary" onClick={() => geocodeSites() }>Geocode Sites</Button>   
            <Button fullWidth variant="outlined" color="primary" onClick={() => testWorkerRun()  }>Test Worker</Button>   
            <Button fullWidth variant="outlined" color="primary" onClick={() => ds.forceSave() }>Trigger save task</Button>  
            <Button fullWidth variant="outlined" color="primary" onClick={cleanDatastore}>Job ids</Button>  
            <Button fullWidth variant="outlined" color="primary" onClick={updateJHa}>Job Update JHA</Button>  

            

         
            <Button fullWidth variant="outlined" color="primary" onClick={createUnit}>createUnit</Button>  


            
         
            
         
            </div>
        </CardContent>
</Card>

    
		<Navigation target="admin"  />
	</div>  
    
}

/*

  <br/>
     <Card>
        <CardContent>
            <Typography variant="h5">User</Typography>
        </CardContent>
        <Divider />
        <CardContent>
            <pre>{JSON.stringify(ds.db.getMe().toJSON(),undefined,3)}</pre>
        </CardContent>
     </Card>


     */