export interface Record590G {
  rec_num: number;
  rec_ver: number;
  time_stamp: number;
  operator: string;
  test_menu_id: number;
  model_num: string;
  serial_num: string;
  location: string;
  color:string;
  prim_i: number;
  sec_i: number;
  rating: number;
  ct_class: string;
  power_factor: number;
  tp_menu_id: number;
  line_freq: number;
  quick_ratio: number;
  wind_dc_res: number;
  final_ratio: number[];
  ys_50hz: number[];
  ys_1600hz: number[];
  burden_imp: number[];
  tp_name: string;
  burden_pts: number[];
  inj_pts: number[];
  burden_pts_used: number;
  inj_pts_used: number;
  test_class_acc: [number, string];
  //class_acc_data:number[];
  eva_result_flags: string;
  ct_mag_ph_err_a: number[];
  ct_mag_ph_err_b: number[];
  knee_pt_45deg: number[];
  knee_pt_ansi: number[];
  knee_pt_iec: number[];
  magnet_pts_used: number;
  magnet_curve: number[];
  passed:string
}

export function unPackTextRecord590GV2(txt: string) {
  let lines = txt.split("\n");
  lines = lines.map((s) => s.trim());

  //strip out comments
  lines = lines.filter((l) => l[1] !== "#");

  //returnAttributeFloat

  const rec_num: number = returnAttributeInt("rec_num", lines, 0);
  const rec_ver: number = returnAttributeInt("rec_ver", lines, 1);
  const time_stamp: number = returnAttributeTimestamp("time_stamp", lines, 2);
  const operator: string = returnAttributeString("operator", lines, 3);
  const test_menu_id: number = returnAttributeInt("test_menu_id", lines, 4);
  const model_num: string = returnAttributeString("model_num", lines, 5);
  const serial_num: string = returnAttributeString("serial_num", lines, 6);
  const locationSplit: string[] = returnAttributeString("location", lines, 7).split(" ");
  const prim_i: number = returnAttributeFloat("prim_i", lines, 8);
  const sec_i: number = returnAttributeFloat("sec_i", lines, 9);
  const rating: number = returnAttributeFloat("rating", lines, 10);
  const ct_class: string = returnAttributeString("ct_class", lines, 11);
  const power_factor: number = returnAttributeFloat("power_factor", lines, 12);
  const tp_menu_id: number = returnAttributeInt("tp_menu_id", lines, 13);
  const line_freq: number = returnAttributeFloat("line_freq", lines, 14);
  const quick_ratio: number = returnAttributeFloat("quick_ratio", lines, 15);
  const wind_dc_res: number = returnAttributeFloat("wind_dc_res", lines, 16);
  const final_ratio: number[] = returnAttributeFloatArray(
    "final_ratio",
    lines,
    17
  );
  const ys_50hz: number[] = returnAttributeFloatArray("ys_50hz", lines, 18);
  const ys_1600hz: number[] = returnAttributeFloatArray("ys_1600hz", lines, 19);
  const burden_imp: number[] = returnAttributeFloatArray("burden_imp", lines, 20);
  const tp_name: string = returnAttributeString("tp_name", lines, 21);
  const burden_pts: number[] = returnAttributeFloatArray("burden_pts", lines, 22);
  const inj_pts: number[] = returnAttributeFloatArray("inj_pts", lines, 23);
  const burden_pts_used: number = returnAttributeInt(
    "burden_pts_used",
    lines,
    24
  );
  const inj_pts_used: number = returnAttributeInt("inj_pts_used", lines, 25);
  const tmp = returnAttributeRaw("test_class_acc", lines, 26);
  const test_class_acc: [number, string] = [parseInt(tmp[0]), tmp[1]];
  //let class_acc_data:number = ; // 27
  const eva_result_flags: string = returnAttributeString(
    "eva_result_flags",
    lines,
    28
  );

  const passed: string = returnAttributeStringInner(
    "eva_result_flags",
    lines,
    28
  );


  

  const ct_mag_ph_err_a: number[] = returnAttributeFloatArray(
    "ct_mag_ph_err",
    lines,
    29
  );
  const ct_mag_ph_err_b: number[] = returnAttributeFloatArray(
    "ct_mag_ph_err",
    lines,
    30
  );
  const knee_pt_45deg: number[] = returnAttributeFloatArray(
    "knee_pt_45deg",
    lines,
    31
  );
  const knee_pt_ansi: number[] = returnAttributeFloatArray(
    "knee_pt_ansi",
    lines,
    32
  );
  const knee_pt_iec: number[] = returnAttributeFloatArray(
    "knee_pt_iec",
    lines,
    33
  );
  const magnet_pts_used: number = returnAttributeInt(
    "magnet_pts_used",
    lines,
    34
  );
  const magnet_curve: number[] = returnAttributeFloatArray(
    "magnet_curve",
    lines,
    35
  );


  const location = locationSplit[0];
  const color = locationSplit[1];

  

  const record: Record590G = {
    rec_num,
    rec_ver,
    time_stamp,
    operator,
    test_menu_id,
    model_num,
    serial_num,
    location,
    prim_i,
    sec_i,
    rating,
    ct_class,
    power_factor,
    color,
    tp_menu_id,
    line_freq,
    quick_ratio,
    wind_dc_res,
    final_ratio,
    ys_50hz,
    ys_1600hz,
    burden_imp,
    tp_name,
    burden_pts,
    inj_pts,
    burden_pts_used,
    inj_pts_used,
    test_class_acc,
    // class_acc_data,
    eva_result_flags,
    ct_mag_ph_err_a,
    ct_mag_ph_err_b,
    knee_pt_45deg,
    knee_pt_ansi,
    knee_pt_iec,
    magnet_pts_used,
    magnet_curve,
    passed
  };

  return record;
}

export function parseFile590GV2(file: string) {
  const lines = file
    .trim()
    .split("\n")
    .map((r) => r.trim());
  //console.log(lines);
  //skip initial comments
  let i = 0;
  for (; i < lines.length; i++) {
    const line = lines[i];
    //console.log( line[1] );
    if (line[1] != "#") break;
  }

  const records: string[] = [];

  let currentRecord = "";
  let inRecord = false;

  for (i; i < lines.length; i++) {
    const line = lines[i];

    switch (line) {
      case "<ct_record_start>":
        if (inRecord) {
          throw "Record not started correctly";
        } else if (!inRecord) {
          inRecord = true;
        }
        break;
      case "<ct_record_end>":
        if (!inRecord) {
          throw "Record not closed correctly";
        } else if (inRecord) {
          inRecord = false;
          records.push(currentRecord);
          //console.log(currentRecord);
          currentRecord = "";
        }
        break;
      case "":
        // skip blanks
        break;

      default:
        currentRecord += line + "\r\n";
        break;
    }
  }

  //console.log(records);
  return records;
}

function returnAttributeRaw(key: string, lines: string[], index: number) {
  const row = lines[index].split(",");
  const rowKey = row[0];

  if (rowKey != key) {
    //console.log(row);
    throw "Unexpected key at " + key + " " + rowKey + "\n ";
  }

  return row.slice(2);
}

function returnAllAttributeRaw(key: string, lines: string[], index: number) {
  const row = lines[index].split(",");
  const rowKey = row[0];

  if (rowKey != key) {
    //console.log(row);
    throw "Unexpected key at " + key + " " + rowKey + "\n ";
  }

  return row.slice(1);
}


function returnAttributeInt(key: string, lines: string[], index: number) {
  const raw = returnAttributeRaw(key, lines, index)[0];
  const result = parseInt(raw);
  if (isNaN(result)) throw `Error parsing int ${key} ${result} from ${raw} `;
  return result;
}

function returnAttributeFloat(key: string, lines: string[], index: number) {
  const raw = returnAttributeRaw(key, lines, index)[0];
  const result = parseFloat(raw);
  if (isNaN(result)) throw `Error parsing float ${key}=${result} from ${raw}`;
  return result;
}

function returnAttributeFloatArray(
  key: string,
  lines: string[],
  index: number
) {
  const result = returnAttributeRaw(key, lines, index).map((v) => parseFloat(v));

  for (const v of result)
    if (isNaN(v)) throw `Error parsing float array for${key} with ${v}`;

  return result;
}

function returnAttributeString(key: string, lines: string[], index: number) {
  let result = returnAttributeRaw(key, lines, index)[0];
  //strip out string encapsulation
  result = result.replace(new RegExp(`\\"`, "g"), "");
  return result.trim();
}

function returnAttributeTimestamp(key: string, lines: string[], index: number) {
  const r = returnAttributeRaw(key, lines, index).map((v) => parseInt(v));

  if (r.length != 6) throw "incorrect date input";

  const date = new Date(r[2] + 2000, r[1] - 1, r[0], r[3], r[4], r[5]);
  return date.valueOf();
}

function returnAttributeStringInner(key: string, lines: string[], index: number) {
  let result = returnAllAttributeRaw(key, lines, index)[0];
  //strip out string encapsulation
  result = result.replace(new RegExp(`\\"`, "g"), "");
  return result.trim();
}

