import moment from "moment";
import { DataEvent, Datastore } from "../Datastore";
import { isEmpty } from "../Data";

import { Base } from "./Base";
import { Job } from "./Job";
import { TaskData, TaskDataI } from "./TaskData";
import { TaskType } from "./TaskType";
import { TaskTypeData } from "./TaskTypeData";
import { Tech } from "./Tech";
import { TestResult } from "./TestResult";
import { Site } from "./Site";
import { within } from "../within";
import { MuiColor } from "../../css-types";
import { AutoTestResult } from "./AutoTestResult";
import { TestUnit } from "./TestUnit";
import { TestResultRow } from "./TestResultRow";
import { TestResult590BV2 } from "./TestResult590BV2";
import { ratesList, rateLookup, Rate } from "../../page/profitLossAnalysis/rates";

interface TestResultFile {
  filename: string;
  data: string;
}

interface TaskDataUpdate {
  value?: any;
  valueID?: number | null;
}

export interface TaskJ {
  id: number;
  type: string;
  deleted: boolean;
  jobID: number;
  typeID: number;
  scheduleTimestamp: number | null;
  startTimestamp: number | null;
  endTimestamp: number | null;
  techID: number | null;
  status: number;

  // testValidation: string|null;
  ignoreValidation: boolean;
}

export enum TaskStatus {
  Pending,
  Successful,
  Cancelled,
  Aborted,
  UnableToComplete
}

export enum TaskState {
  Completed,
  Unplanned,
  Scheduled,
  InProgress,
  Cancelled,
  Aborted,
}

export function TaskStateToColor(state: TaskState) {
  switch (state) {
    case TaskState.Unplanned:
      return "warning";
    case TaskState.Scheduled:
      return "info";
    case TaskState.InProgress:
      return "info";
    case TaskState.Completed:
      return "success";
    case TaskState.Aborted:
      return "error";
    case TaskState.Cancelled:
      return "warning";
  }
}

export function TaskStateToString(state: TaskState) {
  switch (state) {
    case TaskState.Unplanned:
      return "Unplanned";
    case TaskState.Scheduled:
      return "Scheduled";
    case TaskState.InProgress:
      return "In Progress";
    case TaskState.Completed:
      return "Completed";
    case TaskState.Aborted:
      return "Aborted";
    case TaskState.Cancelled:
      return "Cancelled";
  }
}





export class Task extends Base {
  jobID: number;
  typeID: number;
  scheduleTimestamp: number | null;
  startTimestamp: number | null;
  endTimestamp: number | null;
  status: TaskStatus;
  ignoreValidation: boolean;

  techID: number;

  cachedIssue: string;
  // testValidation: string;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.jobID = event.data.jobID;
    this.typeID = event.data.typeID;
    this.techID = event.data.techID;
    this.scheduleTimestamp = event.data.scheduleTimestamp;
    this.startTimestamp = event.data.startTimestamp;
    this.endTimestamp = event.data.endTimestamp;
    this.status = event.data.status;
    this.ignoreValidation = event.data.ignoreValidation;
    // this.testValidation = "";
    this.cachedIssue = "";
  }

  getRate():Rate{
    const r = ratesList;
    const taskType = this.getType();
    const type = taskType.toString();

    if(this.isAborted()) return rateLookup.Rate_21;
    if(this.isCancelled()) return rateLookup.Canceled;


    

    if(type === "Meter Test"){
      let phases: number = parseInt(this.getDataString("Number of Phases").toString());
      if (isNaN(phases)){
          throw new Error(`Error number of phases id:${this.id}`)
      }


      let elements: number = parseInt(this.getDataString("Number of Elements").toString());
      if (isNaN(elements)){
        throw new Error(`Error number of elements id:${this.id}`)
      }


      if(phases === 1 && elements === 1)
        return rateLookup.Rate_13a;

      if(phases === 1 && elements === 2)
        return rateLookup.Rate_13b;


      if(phases === 2 && elements === 1)
        return rateLookup.Rate_13b;


      if(phases === 3)
        return rateLookup.Rate_13c;


      throw new Error(`Invalid config Phase:${phases} element:${elements}`)

      // ["Rate_13a","Sample Testing - One Phase WC meter", 212.00, 212.00, "Contract"],
      // ["Rate_13b","Sample Testing - One Phase 2 element WC meter (420, 520)", 230.00, 230.00, "Contract"],
      // ["Rate_13b","Sample Testing - Three Phase WC meter", 243.00, 243.00, "Contract"],


    }

    if(type === "Pre-inspection"){
      return rateLookup.Rate_14;
    }

    if(type === "CT Testing"){
      let elementCount: number = parseInt(this.getDataString("CT Tapping Count").toString());
      if (isNaN(elementCount))
        throw new Error("CT Tapping Count");
      
      if(elementCount === 1)
          return rateLookup.Rate_15

      return rateLookup.Rate_16;

    }

    return rateLookup.NA
    
  }

  getProfit():number{
    const rate = this.getRate();
    return rate.businessHours;
  }


  isToday() {
    const d = this.getTimestamp();
    return d.isSame(moment(), "day");
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.jobID = transform(this.jobID);
    this.typeID = transform(this.typeID);
    this.techID = transform(this.techID);
  }

  toSearchString() {
    const type = this.getType();
    const job = this.getJob();
    return `${type.toSearchString()} ${job.toSearchString()} 'Task'`;
  }

  getStateColor(): MuiColor {
    const state = this.getState();
    return TaskStateToColor(state);
  }

  forTech(tech: Tech) {
    return this.techID === tech.id;
  }

  forMe() {
    try {
      const me = this.ds.db.getMe();
      if (me.techID === null) {
        return false;
      } else {
        return me.techID === this.techID;
      }
    } catch (E) {
      console.log(E);
      return false;
    }
  }

  getTech(): Tech {
    if (this.techID == null) throw "Tech not assigned";
    return this.ds.db.get(this.techID);
  }

  getType(): TaskType {
    return this.ds.db.get(this.typeID) as TaskType;
  }

  getJob(): Job {
    return this.ds.db.get(this.jobID) as Job;
  }

  getSite(): Site {
    const job = this.getJob();
    return job.getSite();
  }

  getTimestamp() {
    const t = (() => {
      if (this.endTimestamp != null) return this.getEndTimestamp();
      if(this.startTimestamp != null) return this.getStartTimestamp();
      return this.getScheduleTimestamp();
    })();
    
    return t;
  }

  getScheduleTimestamp() {
    return moment(this.scheduleTimestamp);
  }

  getStartTimestamp() {
    return moment(this.startTimestamp);
  }

  getEndTimestamp() {
    return moment(this.endTimestamp);
  }

  isAfterHours() {
    const format = "HH:mm";
    const date = this.getStartTimestamp();
    const startTime = moment(this.getStartTimestamp().format(format), format);
    const endTime = moment(this.getEndTimestamp().format(format), format);

    //   infer by after hours 6-6 mon-fri
    const weekdays = [1, 2, 3, 4, 5];
    const day = date.isoWeekday();
    if (weekdays.indexOf(day) == -1) return true;

    const normalHoursStart = moment("06:00", format);
    const normalHoursEnd = moment("18:00", format);

    return !(
      startTime.isBetween(normalHoursStart, normalHoursEnd, undefined, "[]") &&
      endTime.isBetween(normalHoursStart, normalHoursEnd, undefined, "[]")
    );
  }

  getTimeString() {
    if (this.scheduleTimestamp == null && this.endTimestamp == null)
      return "Time NA";
    const time = this.getTimestamp();
    return time.format("HH:mm");
  }

  getState(): TaskState {
    if (this.status == TaskStatus.Cancelled) return TaskState.Cancelled;
    else if (this.status == TaskStatus.Aborted) return TaskState.Aborted;
    else if (this.status == TaskStatus.Successful) return TaskState.Completed;
    else if (this.status == TaskStatus.UnableToComplete) return TaskState.Completed;
    else if (this.status == TaskStatus.Pending) {
      if (this.startTimestamp != null) return TaskState.InProgress;
      else if (this.scheduleTimestamp != null) return TaskState.Scheduled;
      else return TaskState.Unplanned;
    } else {
      return TaskState.Unplanned;
    }
  }

  isPending(): boolean {
    return this.status == TaskStatus.Pending;
  }

  isSuccessful(): boolean {
    return (
      this.status == TaskStatus.Successful ||
      this.status == TaskStatus.UnableToComplete
    );
  }

  isUnsuccessful(): boolean {
    return (
      this.status == TaskStatus.Cancelled || this.status == TaskStatus.Aborted 
    );
  }

  isComplete(): boolean {
    return (
      this.status == TaskStatus.Successful ||
      this.status == TaskStatus.Cancelled ||
      this.status == TaskStatus.Aborted ||
      this.status == TaskStatus.UnableToComplete
    );
  }


  isAborted():boolean{
    return this.status == TaskStatus.Aborted
  }


  isCancelled():boolean{
    return this.status == TaskStatus.Cancelled
  }



  getTaskStatus(){
    const l:Record<TaskStatus,string> = {
      [TaskStatus.Aborted]:"Aborted",
      [TaskStatus.Cancelled]:"Cancelled",
      [TaskStatus.Pending]:"Pending",
      [TaskStatus.Successful]:"Successful",
      [TaskStatus.UnableToComplete]:"Unable To Complete"
    } 

    return l[this.status];
  }

  hasIssues(): boolean {
    const issue = this.getIssue();
    return issue.length > 0;
  }

  getIssue(): string {
    return this.cachedIssue;
  }

  processIssues() {

    if (this.ignoreValidation) return;


    this.cachedIssue = "";
    const fields = ["startTimestamp", "endTimestamp", "status", "techID"];
    for (const key of fields) {
      const msg = this.validateAttribute(key);
      if (msg.length != 0) {
        this.cachedIssue = key + " " + msg;
        // console.log("this.cachedIssue",this.cachedIssue);
        break;
      }
    }


    const job = this.getJob();

    if(job.sent){
      this.cachedIssue = "";
      return;
    }

    //only validate if successful
    if (this.status !== TaskStatus.Pending) {

       this.cachedIssue = this.validateTestResults();

       if (this.cachedIssue === ""){
          const type = this.getType();
          const datas = type.getData();
          for (const data of datas) {
            const msg = this.validateData(data);
            if (msg.length !== 0) {
              this.cachedIssue = data.name + " " + msg;
              break;
            }
          }
        }
    }

    return "";
  }

  getTaskData(): TaskData[] {
    return this.ds.db
      .filter({ type: "TaskData" })
      .filter((t) => t.taskID == this.id);
  }

  getJobDataItem(data: TaskTypeData) {
    // console.log("getJobDataItem",data);
    const datas = this.getTaskData();
    const match = datas.filter((d) => d.dataID == data.id);
    //console.log(match);
    if (match.length == 0) throw "No match";
    else if (match.length > 1) {
      //console.error("multiple matches",match);
      return match[match.length - 1]; //"Multiple matched data"
    } else return match[0];
  }

  getJobDataItemKey(key: string) {
    const type = this.getType();
    const attributes = type.getData();
    const match = attributes.filter((a) => a.name == key);

    if (match.length == 0) throw "No Match";
    else if (match.length > 1) {
      throw "Multiple matches";
    } else {
      const attribute = match[0];
      return this.getJobDataItem(attribute);
    }
  }

  async getsertJobDataItemKey(key: string): Promise<TaskData> {
    const type = this.getType();
    const attributes = type.getData();
    const match = attributes.filter((a) => a.name == key);

    if (match.length > 1) {
      // console.log(match)
      throw "Multiple matches"; //throw "Multiple matches"
    } else if (match.length == 0) {
      throw "Did not find matching attribute";
    } else {
      try {
        return this.getJobDataItem(match[0]);
      } catch (E) {
        const d: TaskDataI = {
          id: 0,
          type: "TaskData",
          deleted: false,
          taskID: this.id,
          dataID: match[0].id,
          value: null,
          valueID: null,
        };
        const t: TaskData = (await this.ds.createEntity(d)) as TaskData;
        return t;
      }
    }
  }

  getJobDataItemString(key: string) {
    const type = this.getType();
    const attributes = type.getData();
    const match = attributes.filter((a) => a.name == key);

    if (match.length == 0) return "";
    else if (match.length > 1) {
      return "Multiple matches"; //throw "Multiple matches"
    } else {
      try {
        const attribute = match[0];
        const dataType = attribute.getDataType();
        if (dataType.name == "id") return this.getJobDataItem(match[0]).valueID;
        else return this.getJobDataItem(match[0]).value;
      } catch (E) {
        return "";
      }
    }
  }

  getJobDataItemValueString(key: string) {
    const type = this.getType();
    const attributes = type.getData();
    const match = attributes.filter((a) => a.name == key);

    if (match.length == 0) return "";
    else if (match.length > 1) {
      return "Multiple matches"; //throw "Multiple matches"
    } else {
      try {
        const attribute = match[0];
        const dataType = attribute.getDataType();
        if (dataType.name == "id") {
          const id = this.getJobDataItem(match[0]).valueID;
          if (id != null) return this.ds.db.get(id);
          else return "";
        } else {
          const v = this.getJobDataItem(match[0]).value;
          if (v == null) return "";
          else return v;
        }
      } catch (E) {
        return "";
      }
    }
  }

  getDuration() {
    const start = this.getStartTimestamp();
    const fin = this.getEndTimestamp();
    const diff = fin.valueOf() - start.valueOf();
    const hours = diff / (60 * 60 * 1000);
    return Math.round(hours * 10) / 10;
  }

  validateAttribute(key: string) {
    const a: any = this;
    const v = a[key];


    switch (key) {
      case "scheduleTimestamp":
        return "";

      case "techID":
        if (
          (this.status == TaskStatus.Successful ||
            this.status == TaskStatus.UnableToComplete ||
            this.status == TaskStatus.Aborted) &&
          isEmpty(v)
        )
          return "Required";
          
        return "";

      case "startTimestamp":
      case "endTimestamp":
        if (
          (this.status == TaskStatus.Successful ||
            this.status == TaskStatus.UnableToComplete ||
            this.status == TaskStatus.Aborted) &&
          isEmpty(v)
        )
          return "Required";

        if (
          (this.status == TaskStatus.Successful ||
            this.status == TaskStatus.UnableToComplete ||
            this.status == TaskStatus.Aborted) &&
          !moment(v).isValid()
        )
          return "Invalid";

        if (
          (this.status == TaskStatus.Successful ||
            this.status == TaskStatus.UnableToComplete ||
            this.status == TaskStatus.Aborted) &&
          this.startTimestamp != null &&
          this.endTimestamp != null &&
          this.startTimestamp > this.endTimestamp
        )
          return "Start time is after end time!";

        return "";

      case "status":
        if (
          (this.startTimestamp != null || this.endTimestamp != null) &&
          this.status == TaskStatus.Pending
        )
          return "Required";
        return "";

      default:
        throw `Attribute '${key}' not supported`;
    }
  }

  async upsertTaskData(taskDataKey: string, value: TaskDataUpdate) {
    const taskData = await this.getsertJobDataItemKey(taskDataKey);
    await this.ds.updateEntityField(taskData, value);
  }

  validateData(taskTypeData: TaskTypeData): string {
    if (this.ignoreValidation) return "";
    // if (this.typeID !== 203) return "";

    let value: any;
    try {
      value = this.getJobDataItem(taskTypeData);
    } catch (E) {
      value = undefined;
    }

    try {
      const job = this.getJob();
      const site = job.getSite();

      if (
        this.status == TaskStatus.Aborted ||
        this.status == TaskStatus.Cancelled
      ) {
        switch (taskTypeData.name) {
          case "Comments":
            if (isEmpty(value.value)) return "Required";
            return "";

          default:
            return "";
        }
      }

      // let elementCount: string | number = parseInt(
      //   this.getDataString("Number of Elements").toString()
      // );
      // if (isNaN(elementCount)) elementCount = "";

      switch (taskTypeData.name) {
        case "JHA Instructions":
        case "JHA PPE":
        case "JHA Induction":
        case "JHA Access":
        case "JHA Isolated":
        case "JHA Live Points":
        case "JHA Traffic":
          if ( this.status === TaskStatus.Successful && isEmpty(value.value)) return "Required";

          return "";

        case "Found Meter Condition":
        case "Number of Elements":
        case "Number of Phases":
          if (isEmpty(value.valueID)) return "Required";
          return "";

        case "Meter panel as found photo":
          // case "Meter Serial Number & date as found photo":
          // case "Meter panel as left photo":
          // case "Switchboard Circuit breakers as found photo":
          // case "Switchboard Circuit breakers as left photo":
          if (isEmpty(value.valueID)) return "Required";
          return "";

        case "Initial Total Kwh":
        case "Initial Peak Kwh":
        case "Initial Off Peak Kwh":
        case "Initial Dedicated Circuit Kwh":
          // if (isEmpty(value.value)) return "Required";
          return "";

        case "Final Total Kwh":
        case "Final Peak Kwh":
        case "Final Off Peak Kwh":
        case "Final Dedicated Circuit Kwh": {
          const init = taskTypeData.name.replace("Final", "Initial");
          // console.log(init, this.getJobDataItemValueString(init));
          if (this.getJobDataItemValueString(init) !== "") {
            if (isEmpty(value.value)) return "Required";
          }

          return "";
        }
        case "Test Voltage Red":
        // case "Test Voltage White":
        // case "Test Voltage Blue":
        // case "Test Temperature":
        case "Register Test High":
        case "Register Test Low":
          if (isEmpty(value.value)) return "Required";
          return "";

        case "Meter Current Rating":
          if (isEmpty(value.valueID)) return "Required";
          return "";

        case "Element 1 % Error Test 1":
        case "Element 1 % Error Test 2":
        case "Element 1 % Error Test 3":
        case "Element 1 % Error Test 4":
        case "Element 1 % Error Test 5":
        case "Element 1 % Error Test 6":
        case "Element 1 % Error Test 7":
        case "Element 1 % Error Test 8":
        case "Element 1 % Error Test 9": {
          if (isEmpty(value.value)) return "Required";
          // let result = this.validateErrorAgainstResult(
          //   taskTypeData.name,
          //   value.value
          // );
          // if (result) return result;
          return "";
        }

        case "Element 1 Error Test 1 Record Number":
        case "Element 1 Error Test 2 Record Number":
        case "Element 1 Error Test 3 Record Number":
        case "Element 1 Error Test 4 Record Number":
        case "Element 1 Error Test 5 Record Number":
        case "Element 1 Error Test 6 Record Number":
        case "Element 1 Error Test 7 Record Number":
        case "Element 1 Error Test 8 Record Number":
        case "Element 1 Error Test 9 Record Number": {
          if (isEmpty(value.value)) return "Required";
          // if (this.checkDuplicateRecordNumber(value.value))
          //   return "Duplicate record number";

          // let recordNumberValidation = this.validateRecordNumber(
          //   taskTypeData.name,
          //   value.value
          // );
          // if (recordNumberValidation) return recordNumberValidation;

          // let result = this.checkPowerFactor(taskTypeData.name, value.value);
          // if (result) return result;

          return "";
        }
        case "Element 1 Error Test 1 Result":
        case "Element 1 Error Test 2 Result":
        case "Element 1 Error Test 3 Result":
        case "Element 1 Error Test 4 Result":
        case "Element 1 Error Test 5 Result":
        case "Element 1 Error Test 6 Result":
        case "Element 1 Error Test 7 Result":
        case "Element 1 Error Test 8 Result":
        case "Element 1 Error Test 9 Result":
          // if (isEmpty(value.valueID)) return "Required";
          return "";

        case "Element 2 % Error Test 1":
        case "Element 2 % Error Test 2":
        case "Element 2 % Error Test 3":
        case "Element 2 % Error Test 4":
        case "Element 2 % Error Test 5":
        case "Element 2 % Error Test 6":
        case "Element 2 % Error Test 7":
        case "Element 2 % Error Test 8":
        case "Element 2 % Error Test 9": {
          // if (elementCount === 2) {
          //   if (isEmpty(value.value)) return "Required";
          //   let result = this.validateErrorAgainstResult(
          //     taskTypeData.name,
          //     value.value
          //   );
          //   if (result) return result;
          //   return "";
          // }
          return "";
        }

        case "Element 2 Error Test 1 Record Number":
        case "Element 2 Error Test 2 Record Number":
        case "Element 2 Error Test 3 Record Number":
        case "Element 2 Error Test 4 Record Number":
        case "Element 2 Error Test 5 Record Number":
        case "Element 2 Error Test 6 Record Number":
        case "Element 2 Error Test 7 Record Number":
        case "Element 2 Error Test 8 Record Number":
        case "Element 2 Error Test 9 Record Number": {
          // if (elementCount === 2 && isEmpty(value.value)) return "Required";
          // if (this.checkDuplicateRecordNumber(value.value))
          //   return "Duplicate record number";

          // let recordNumberValidation = this.validateRecordNumber(
          //   taskTypeData.name,
          //   value.value
          // );
          // if (recordNumberValidation) return recordNumberValidation;

          // let result = this.checkPowerFactor(taskTypeData.name, value.value);
          // if (result) return result;

          return "";
        }
        case "Element 2 Error Test 1 Result":
        case "Element 2 Error Test 2 Result":
        case "Element 2 Error Test 3 Result":
        case "Element 2 Error Test 4 Result":
        case "Element 2 Error Test 5 Result":
        case "Element 2 Error Test 6 Result":
        case "Element 2 Error Test 7 Result":
        case "Element 2 Error Test 8 Result":
        case "Element 2 Error Test 9 Result":
          // if (elementCount === 2 && isEmpty(value.valueID)) return "Required";
          return "";

        default:
          return "";
      }
    } catch (E) {
      return "Invalid validation";
    }
  }

  validateTestResults() {
    // console.log("this.status", this.status);
    if (this.status != TaskStatus.Successful) return "";


  if(this.typeID === 9 )
    return ""



    if (this.typeID === 203){


      let elementCount: number = parseInt(
        this.getDataString("Number of Elements").toString()
      );
    if (isNaN(elementCount)) {
      return "Invalid number of elements";
    }

    const autoTests = this.ds.db.filter({
      type: "AutoTestResult",
      taskID: this.id,
    });

    if (autoTests.length > 0) {
      if (autoTests.length != elementCount)
        return "Element count does not match auto test length";
      return "";
    }

    const testResultRow = this.ds.db.filter({
      type: "TestResultRow",
      taskID: this.id,
    });

    if (testResultRow.length > 0) {
      if (testResultRow.length != elementCount * 9)
        return "Element count does not match regular result length";
      return "";
    }







// Element ${element} Error Test ${test} Result
// CT ${p} Phase Tapping ${c} Result
    // then this is a classical test
    const elements = [];
    for (let i = 0; i < elementCount; i++) elements.push(i + 1);

    const classRows:string[] = [];

    for (const element of elements) {
      for (let test = 1; test <= 9; test++) {
        const resultKey = `Element ${element} Error Test ${test} Result`// `CT ${element} Phase Tapping ${test} Result`;
       
        const resultID = this.getJobDataItemString(resultKey);
        
        // console.log("checking", resultKey, resultID);
        if (isEmpty(resultID)) return "Missing " + resultKey;
        else
        classRows.push(resultID);

       
      }
    }
    if(classRows.length === 0)
      return "No test result results uploaded";
  return "";

  }


  if(this.typeID === 10){

    let elementCount: number = parseInt(
      (this.getDataString("CT Tapping Count")??"").toString()
    );
    if (isNaN(elementCount)) {
      return "Invalid number of elements";
    }

    const testResult590Row = this.ds.db.filter({
      type: "TestResult590BV2",
      taskID: this.id,
    });

      if (testResult590Row.length != elementCount*3 )
        return "Element count does not match regular result length";
      else
        return "";
    
  }



  return "No test results";
  }




  getTestPass() {
    
    // console.log('validation',validation);
    // if (validation.length > 0) throw validation;

    const autoTests: AutoTestResult[] = this.ds.db.filter({
      type: "AutoTestResult",
      taskID: this.id,
    });

    if (autoTests.length > 0) {
      return autoTests.every(a => a.decode().testSteps.every(t => t.result === "PASS"))
    }

    const testsResultRow: TestResultRow[] = this.ds.db.filter({
      type: "TestResultRow",
      taskID: this.id,
    });

    if (testsResultRow.length > 0) {
      return false;
    }


    const testsResult590BV2Row: TestResult590BV2[] = this.ds.db.filter({
      type: "TestResult590BV2",
      taskID: this.id,
    });

    // console.log('testsResult590BV2Row', testsResult590BV2Row);

    if (testsResult590BV2Row.length > 0) {
      return testsResult590BV2Row.every( t=> {
        const a = t.decode().passed;
        console.log(a)
      return a.includes("PASS")
      
    })
    }




    const resultKey = `Element 1 Error Test 1 Result`;
    const [type, recordLink] = this.getData(resultKey);
    if (recordLink.valueID !== null){ 
      const record: TestResult = this.ds.db.get(recordLink.valueID);
      if(record)
        return true;
    }

    return false
    
  }






  getTestUnit(): TestUnit {
    const validation = this.validateTestResults();
    // console.log('validation',validation);
    if (validation.length > 0) throw validation;

    const autoTests: AutoTestResult[] = this.ds.db.filter({
      type: "AutoTestResult",
      taskID: this.id,
    });

    if (autoTests.length > 0) {
      return autoTests[0].getTestUnit();
    }

    const testsResultRow: TestResultRow[] = this.ds.db.filter({
      type: "TestResultRow",
      taskID: this.id,
    });

    if (testsResultRow.length > 0) {
      return testsResultRow[0].getTestUnit();
    }


    const testsResult590BV2Row: TestResult590BV2[] = this.ds.db.filter({
      type: "TestResult590BV2",
      taskID: this.id,
    });

    // console.log('testsResult590BV2Row', testsResult590BV2Row);

    if (testsResult590BV2Row.length > 0) {
      return testsResult590BV2Row[0].getTestUnit();
    }

    const resultKey = `Element 1 Error Test 1 Result`;
    const [type, recordLink] = this.getData(resultKey);
    if (recordLink.valueID === null) throw new Error("Missing " + resultKey);
    const record: TestResult = this.ds.db.get(recordLink.valueID);
    return record.getTestUnit();
  }

  // getTestResultsStruct() {
  //   const validation = this.validateTestResults();
  //   if (validation.length > 0) throw validation;

  //   let elementCount: number = parseInt(
  //     this.getDataString("Number of Elements").toString()
  //   );

  //   const autoTests: AutoTestResult[] = this.ds.db.filter({
  //     type: "AutoTestResult",
  //     taskID: this.id,
  //   });

  //   if (autoTests.length > 0) {
  //     // let results:number[] = [];
  //     const tests = autoTests
  //     .sort((a, b) => (a.element === null ? 1 : a.element) - (b.element === null ? 1 : b.element))
  //       .map((t) => t.decode())
  //       .flatMap((a) => a.testSteps)
  //       .map((a) => a.result);
  //     return tests;
  //   }

  //   const testsResultRow: TestResultRow[] = this.ds.db.filter({
  //     type: "TestResultRow",
  //     taskID: this.id,
  //   });

  //   if (testsResultRow.length > 0) {
  //     // let results:number[] = [];
  //     const tests = testsResultRow
  //       .map((t) => t.decode())
  //       .sort((a, b) => a.rec_num - b.rec_num)
  //       .map((a) => a.mtr_err);
  //     return tests;
  //   }

  //   const testsResult590V2Row: TestResult590BV2[] = this.ds.db.filter({
  //     type: "TestResult590BV2",
  //     taskID: this.id,
  //   });

  //   if (testsResult590V2Row.length > 0) {
  //     // let results:number[] = [];
  //     const tests = testsResult590V2Row
  //       .map((t) => t.decode())
  //       .sort((a, b) => a.rec_num - b.rec_num)
  //       .map((a) => a);
  //     return tests;
  //   }





  //   const elements = [];
  //   for (let i = 0; i < elementCount; i++) elements.push(i + 1);

  //   let results: number[] = [];

  //   for (const element of elements) {
  //     for (let test = 1; test <= 9; test++) {
  //       const resultKey = `Element ${element} Error Test ${test} Result`;
  //       // console.log(resultKey);
  //       const [type, recordLink] = this.getData(resultKey);
  //       if (recordLink.valueID === null)
  //         throw new Error("Missing " + resultKey);
  //       const record = this.ds.db.get(recordLink.valueID);
  //       const decoded = record.decode();

  //       results.push(parseFloat(decoded.errorResult));
  //     }
  //   }
  //   return results;
  // }

  clearResults() {
    const elements = [1, 2];

    for (const element of elements) {
      for (let test = 1; test <= 9; test++) {
        const dataKey = `Element ${element} Error Test ${test} Result`;
        this.upsertTaskData(dataKey, { valueID: null });
      }
    }
  }

  validateRecordNumber(key: string, value: string) {
    const matcher = new RegExp(".+?(\\d).+?(\\d)");
    const regResult = key.match(matcher);
    if (regResult === null || regResult.length < 3)
      throw new Error("Regexp match error");
    const element = regResult[1];
    const test = regResult[2];
    const resultKey = `Element ${element} Error Test ${test} Result`;
    const [type, recordLink] = this.getData(resultKey);
    // console.log(key, resultKey, recordLink);
    if (recordLink.valueID === null) return false;
    const record = this.ds.db.get(recordLink.valueID);
    const recordNumber = record.getRecordNumber();
    const myRecordNumber = parseInt(value);
    if (myRecordNumber !== recordNumber)
      return `${myRecordNumber} does not match linked number ${recordNumber}, please resync test results`;

    return false;
  }

  validateErrorAgainstResult(key: string, value: string) {
    const matcher = new RegExp(".+?(\\d).+?(\\d)");
    const regResult = key.match(matcher);
    if (regResult === null || regResult.length < 3)
      throw new Error("Regexp match error");
    const element = regResult[1];
    const test = regResult[2];
    const resultKey = `Element ${element} Error Test ${test} Result`;
    const [type, recordLink] = this.getData(resultKey);
    // console.log(key, resultKey, recordLink);
    if (recordLink.valueID === null) return false;
    const record = this.ds.db.get(recordLink.valueID);
    const errorResult = record.getErrorResult();
    if (value !== errorResult) return `${value} does not match ${errorResult}`;

    return false;
  }

  checkPowerFactor(key: string, value: string) {
    const powerFactors = [1, 0.866, 0.5];
    const matcher = new RegExp(".+?(\\d).+?(\\d)");
    const regResult = key.match(matcher);
    if (regResult === null || regResult.length < 3)
      throw new Error("Regexp match error");
    const element = parseInt(regResult[1]);
    const test = parseInt(regResult[2]);

    const expectedPowerFactorIndex = ((element - 1) * 9 + test - 1) % 3;
    const expectedPowerFactor = powerFactors[expectedPowerFactorIndex];

    const resultKey = `Element ${element} Error Test ${test} Result`;
    const [type, recordLink] = this.getData(resultKey);
    // console.log(key, resultKey, recordLink);
    if (recordLink.valueID === null) return false;
    const record = this.ds.db.get(recordLink.valueID);
    const testPowerFactor = record.getPowerFactor();

    if (!within(parseFloat(testPowerFactor), expectedPowerFactor, 0.25))
      return `${testPowerFactor} not within 25% of expected power factor of ${expectedPowerFactor}`;

    return false;
  }

  getRecordNumbers() {
    const recordNumbers = [];


    if(this.typeID === 10){
      const phases = ['Red','White','Blue']
    for (const phase of phases) {
        for (let test = 1; test <= 3; test++) {
          const key = `CT ${phase} Phase Tapping ${test} Record Number`
          recordNumbers.push(parseInt(this.getDataString(key)));
        }
      }
    }

    if (this.typeID === 203){
      for (let element = 1; element <= 2; element++) {
        for (let test = 1; test <= 9; test++) {
          const key = `Element ${element} Error Test ${test} Record Number`;
          recordNumbers.push(parseInt(this.getDataString(key)));
        }
      }
    }


    return recordNumbers.filter((f) => !Number.isNaN(f));
  }

  checkDuplicateRecordNumber(v: string | null) {
    const recordNumbers = this.getRecordNumbers();
    if (v === null) return false;

    const vn = parseInt(v);

    if (v === null || v === "") return false;

    if (recordNumbers.filter((n) => vn === n).length === 1) return false;

    return true;
  }

  getTestResultsFiles(): TestResultFile[] {
    const validation = this.validateTestResults();
    // console.log("validation:", validation);
    if (validation.length > 0) throw new Error(validation);


    if(this.typeID === 10){
        const rows = this.getTestResult590BV2RowFiles();
        // console.log('rows',rows);
        return rows;
    }

    if(this.typeID === 203){


      const auto = this.getAutoTestResultFiles();
      // console.log('auto',auto);
      if (auto.length !== 0) return auto;

      const rows = this.getTestResultRowFiles();
      // console.log('rows',rows);
      if (rows.length !== 0) return rows;

      // console.log('getClassicTestResultFiles',this.getClassicTestResultFiles())

      return [this.getClassicTestResultFiles()];
    }

    throw new Error("Could not find results")
  }



  getTestResult590BV2RowFiles(): TestResultFile[] {
    const site = this.getSite();
    const autoTests: TestResult590BV2[] = this.ds.db.filter({
      type: "TestResult590BV2",
      taskID: this.id,
    });

    // console.log(autoTests);

    if(autoTests.length === 0) return []

const header = [
  "# Test result records in CSV format.",
"# Any line start with # is a comment line.",
"# Data lines must be inside xx_record_start and xx_record_end.",
"# Content of a data line: DataName, Description, DataFields.",
].map(t => `"${t}"`).join(`\r\n`)

    const together = [header, ...autoTests.map((v) => `<ct_record_start>\r\n${v.data.trim()}\r\n<ct_record_end>`)]

    return [
      {
        filename: `${site.nmi}.csv`,
        data: together.join("\r\n\r\n"),
      },
    ];
  }





  getTestResultRowFiles(): TestResultFile[] {
    const site = this.getSite();
    const autoTests: AutoTestResult[] = this.ds.db.filter({
      type: "TestResultRow",
      taskID: this.id,
    });

    // console.log(autoTests);

    if(autoTests.length === 0) return []

    const together = autoTests.map((v) => v.data).join("\r\n\r\n");

    return [
      {
        filename: `${site.nmi}.csv`,
        data: together,
      },
    ];
  }

  getAutoTestResultFiles(): TestResultFile[] {
    const site = this.getSite();
    const autoTests: AutoTestResult[] = this.ds.db.filter({
      type: "AutoTestResult",
      taskID: this.id,
    });

    // console.log(autoTests);
    return autoTests.map((t) => ({
      filename: `${site.nmi}-E${t.element}.csv`,
      data: t.data,
    }));
  }

  getClassicTestResultFiles(): TestResultFile {
    // console.log('classic');


    const job = this.getJob();
    const meterModel = job.getJobDataItemValueString("Meter Model");
    const site = this.getSite();
    const tech = this.getTech();
    // console.log(meterModel.toString());

    const elementCount = parseInt(
      this.getJobDataItemValueString("Number of Elements").value
    );

    const elements = [1];
    if (elementCount === 2) elements.push(2);

    const log = [];

    for (const element of elements) {
      for (let test = 1; test <= 9; test++) {
        const dataKey = `Element ${element} Error Test ${test} Result`;

        const id = this.getJobDataItemString(dataKey);
        // const resultID = this.getJobDataItemString(dataKey);
        // console.log(dataKey,id);
        if (isEmpty(id))
          throw new Error("Record link is empty");
        const result: TestResult = this.ds.db.get(id);
        // console.log(result);
        log.push(
          result.convertRecord(meterModel.toString(), site.nmi, tech.toString())
        );
      }
    }

    return { filename: `${site.nmi}.csv`, data: log.join(",,,,,,,\r\n") };
  }

  toString() {
    const job = this.getJob();
    const type = this.getType();
    return `${job.toString()} - ${type.toString()}`;
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      jobID: this.jobID,
      typeID: this.typeID,
      scheduleTimestamp: this.scheduleTimestamp,
      startTimestamp: this.startTimestamp,
      endTimestamp: this.endTimestamp,
      techID: this.techID,
      status: this.status,
      ignoreValidation: this.ignoreValidation,
    };
  }

  processJob() {
    const job = this.getJob();
    // if (!this.isDeleted())
    // console.log(job,this.isDeleted());
    if (!this.isDeleted() && job instanceof Job ) 
      job.addTask(this);
    else
      console.log("there was a problem with", job);
  
  }

  getData(name: string): [TaskTypeData, TaskData] {
    const taskType = this.getType();
    const dataType: TaskTypeData = taskType
      .getData()
      .filter((tt) => tt.name === name)[0];

    let taskData: TaskData;
    try {
      taskData = this.getJobDataItem(dataType);
    } catch (E) {
      // console.error("getData", name);
      //if(E == "No Match")
      //   taskData = this.createJobDataItem(dataType);
      //else
      throw E;
    }
    return [dataType, taskData];
  }

  getDataString(name: string) {
    const [dataType, data] = this.getData(name);
    const type = this.ds.db.get(dataType.dataTypeID).toString();
    // console.log(type);
    if (type === "id") {
      if (data.valueID === null) return "";
      else return this.ds.db.get(data.valueID).toString();
    } else {
      return data.value;
    }

    // console.log(type., type);
    // console.log(data);
  }

  async createJobDataItem(type: TaskTypeData) {
    const d: TaskDataI = {
      id: 0,
      type: "TaskData",
      deleted: false,
      taskID: this.id,
      dataID: type.id,
      value: null,
      valueID: null,
    };
    const taskData: TaskData = (await this.ds.createEntity(d)) as TaskData;
    return taskData;
  }

  async createMissingData() {
    const taskType = this.getType();
    // console.log(taskType);
    const dataTypes = taskType.getData();

    for (const dataType of dataTypes) {
      try {
        this.getJobDataItem(dataType);
      } catch (E) {
        if (E == "No match") await this.createJobDataItem(dataType);
      }
    }
  }
}
