import { within } from "../../within";

export interface Record689 {
  recordNumber: string;
  recordType: string;
  testUnitSerialNumber: string; //operatorID: string;
  meterID: string;
  accuracyClass: string;
  meterConstant: string;
  constantUnit: string;
  testLength: string;
  inputType: string;
  currentMeasurementType: string;
  currentRange: string;
  measurementType: string;
  measurementUnits: string;
  currentSource: string;
  testVoltagePhaseA: string;
  testVoltagePhaseB: string;
  testVoltagePhaseC: string;
  testCurrentPhaseA: string;
  testCurrentPhaseB: string;
  testCurrentPhaseC: string;
  averageTestPowerFactor: string;
  errorResult: string;
  standardDeviation: string;
  date: string;
  time: string;
  checksum: string;
  timestamp: number;
}

const keys = [
  "recordNumber",
  "recordType",
  "testUnitSerialNumber", // "operatorID",
  "meterID",
  "accuracyClass",
  "meterConstant",
  "constantUnit",
  "testLength",
  "inputType",
  "currentMeasurementType",
  "currentRange",
  "measurementType",
  "measurementUnits",
  "currentSource",
  "testVoltagePhaseA",
  "testVoltagePhaseB",
  "testVoltagePhaseC",
  "testCurrentPhaseA",
  "testCurrentPhaseB",
  "testCurrentPhaseC",
  "averageTestPowerFactor",
  "errorResult",
  "standardDeviation",
  "date",
  "time",
  "checksum",
];

export function parseFile689(file: string) {
  const rows = file.trim().split("\n").map(s => s.trim());
  const start = "/*start*/";
  const end = "/*end*/";
  console.log(rows);


  if (rows[0].indexOf(start) === -1)
    throw new Error("File missing start header");
  if (rows[rows.length - 1].indexOf(end) === -1)
    throw new Error("File missing end header");

  rows.shift();
  rows.pop();

  return rows;
}

export function unpackTextRecord689(line: string): Record689 {
  const row = line
    .trim()
    .split(",")
    .map((c) => c.trim());
  const r = row;

  // console.log(line);
  if (r.length !== keys.length) throw new Error("incorrect row size");
  const obj = {} as Record689;

  for (let i = 0; i < r.length; i++) {
    const key = keys[i];
    (obj as any)[key] = r[i];
  }

  const d = obj.date.split(new RegExp("[-/]")).map((s) => parseInt(s));
  const t = obj.time.split(":").map((s) => parseInt(s));
  obj.timestamp = new Date(d[2], d[1] - 1, d[0], t[0], t[1]).valueOf();

  return obj;
}

export function convertRecord(
  r: Record689,
  meterType: string,
  location: string,
  operator: string
) {
  const {
    recordNumber,
    recordType,
    testUnitSerialNumber,
    meterID,
    accuracyClass,
    meterConstant,
    constantUnit,
    inputType, // ‘Handswitch’ or ‘Photocell’
    currentMeasurementType, // ‘Direct’ or ‘Clipon’
    currentRange,
    measurementType,
    measurementUnits,
    currentSource,

    standardDeviation,
    date,
    time,
    checksum,
  } = r;

  const testLength = parseFloat(r.testLength);
  const testVoltagePhaseA = parseFloat(r.testVoltagePhaseA);
  const testVoltagePhaseB = parseFloat(r.testVoltagePhaseB);
  const testVoltagePhaseC = parseFloat(r.testVoltagePhaseC);
  const testCurrentPhaseA = parseFloat(r.testCurrentPhaseA);
  const testCurrentPhaseB = parseFloat(r.testCurrentPhaseB);
  const testCurrentPhaseC = parseFloat(r.testCurrentPhaseC);
  const powerFactor = parseFloat(r.averageTestPowerFactor);

  const errorResult = parseFloat(r.errorResult);

  const t = [];
  t.push(...date.split(new RegExp("[-/]")));
  t.push(...time.split(":"));
  t.push(0);

  const temperature = 12;
  const frequency = (50).toFixed(2);

  const phaseCount =
    (testVoltagePhaseA > 0 ? 1 : 0) +
    (testVoltagePhaseB > 0 ? 1 : 0) +
    (testVoltagePhaseC > 0 ? 1 : 0);

  const powerFactorArray: number[] = [];
  for (let i = 0; i < phaseCount; i++) powerFactorArray.push(powerFactor);

  for (let i = phaseCount; i < 3; i++) powerFactorArray.push(0);

  console.log(powerFactorArray);

  const voltageArray = [testVoltagePhaseA, testVoltagePhaseB, testVoltagePhaseC];
  const currentArray = [testCurrentPhaseA, testCurrentPhaseB, testCurrentPhaseC];
  const apparentPowerArray: number[] = [0, 1, 2].map(
    (i) => voltageArray[i] * currentArray[i]
  );
  let wiringConfiguration: string;

  switch (phaseCount) {
    case 1:
      wiringConfiguration = `1 phase`;
      break;
    case 2:
      wiringConfiguration = `2 phase`;
      break;
    case 3:
      wiringConfiguration = `3 phase`;
      break;
    default:
      throw new Error("invalid Phase count");
  }

  console.log('constantUnit',constantUnit);

  if (!(constantUnit === "Wh/IMP" || constantUnit === "IMPS/Wh")) throw new Error("Constant Unit not Wh/IMP");

  const realPowerArray = [0, 1, 2].map(
    (i) => powerFactorArray[i] * apparentPowerArray[i]
  );
  const reactivePowerArray = [0, 1, 2].map(
    (i) => -Math.sqrt(apparentPowerArray[i] ** 2 - realPowerArray[i] ** 2)
  );
  const phaseAngleArray = [0, 1, 2].map(
    (i) => 60 * ((Math.acos(powerFactorArray[i]) * 180) / Math.PI)
  );
  const energyMeasure = 1 * testLength; // Wh
  const meterEnergyMeasured =
    energyMeasure - energyMeasure * (errorResult / 100);

  let injectedCurrent = 0;
  if (within(testCurrentPhaseA, 10, 0.25) || testCurrentPhaseA > 10)
    injectedCurrent = 10;
  else if (within(testCurrentPhaseA, 5, 0.5)) injectedCurrent = 5;
  else if (within(testCurrentPhaseA, 2.5, 0.5)) injectedCurrent = 2.5;
  else if (within(testCurrentPhaseA, 1, 1)) injectedCurrent = 1;
  else throw new Error(`Invalid injected current ${testCurrentPhaseA}`);

  const degrees = Math.round(phaseAngleArray[0] / 60);

  function dispNumberArray(v: number[]) {
    return v.map((n) => n.toFixed(2)).join(",");
  }

  const str = `<rslt_record_start>,,,,,,,
rec_num,Record number,${recordNumber},,,,,
rec_ver,Record version,1,,,,,
dsp_ver,DSP version,1.26,,,,,
time_stamp,Time stamp: day/month/year/hour/minute/second,${t.join(",")}
operator,Operator name or ID,${operator},,,,,
loc,Location,${location},,,,,
mtr_id,Meter ID,${meterID},,,,,
mtr_type,Meter type,${meterType},,,,,
mtr_class,Meter accuracy class,${accuracyClass},,,,,
wiring,Wiring configuration,"${wiringConfiguration}",,,,,
mtr_const,Meter constant (Wh/Imp ),${meterConstant},,,,,
temp,Temperature (C),${temperature},,,,,
test_mode,Test mode,100A ${currentSource} ,,,,,
inj_cur_ph,Inject current (A) & phase (degree),${injectedCurrent},${degrees},,,,
test_len_p,Test length (pulses),${testLength},,,,,
test_type,Test performed,Meter test ,,,,,
rslt_flags,Result flags,0x00000001,,,,,
mtr_err,Meter error (%),${errorResult},,,,,
meas_energy,Energy measured by tester / by meter (Wh or VARh),${meterEnergyMeasured},${energyMeasure},,,,
freq,Frequency (Hz),${frequency},,,,,
volt,Voltage (V): A/B/C,${testVoltagePhaseA},${testVoltagePhaseB},${testVoltagePhaseC},,,
amps,Current (A): A/B/C,${testCurrentPhaseA},${testCurrentPhaseB},${testCurrentPhaseC},,,
angle,Phase angle ('): A/B/C,${dispNumberArray(phaseAngleArray)},,,
real_pwr,Real power (W): A/B/C,${dispNumberArray(realPowerArray)},,,
react_pwr,Reactive power (VAR): A/B/C,${dispNumberArray(reactivePowerArray)},,,
app_pwr,Apparent power (VA): A/B/C,${dispNumberArray(apparentPowerArray)},,,
pf,Power factor: A/B/C,${powerFactorArray.join(",")},,,
<rslt_record_end>,,,,,,,
`;

  return str;
}
